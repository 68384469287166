import { Portal } from "@radix-ui/react-tooltip";
import { Info } from "lucide-react";
import { PropsWithChildren } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

type IconType = typeof Info;

type TooltipProps = PropsWithChildren<{
  Icon?: IconType;
}>;

/**
 * This defaults to the Info icon, but sometimes it's useful to use a different icon
 */
export function InfoTooltip({ children, Icon = Info }: TooltipProps) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          {/* This is currently hidden because our tooltips are not consistently used throughout the Studio. See SUT-3071 */}
          <button type="button" className="hidden">
            <Icon className="size-4" />
          </button>
        </TooltipTrigger>
        <Portal>
          <TooltipContent side="right" className="max-w-64 text-xs font-medium">
            {children}
          </TooltipContent>
        </Portal>
      </Tooltip>
    </TooltipProvider>
  );
}
